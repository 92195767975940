import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance-message',
  templateUrl: './maintenance-message.component.html',
  styleUrls: []
})
export class MaintenanceMessageComponent {
  notificationVariant = 'warning';
  notificationMessage = 'This page is currently under maintenance. Please try again later.';
}
