import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from '../services/abstracts/auth.service';
import { GuardianService } from '../services/abstracts/guardian.service';

@Injectable({
    providedIn: 'root'
})
export class RolesGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private readonly authService: AuthService,
        protected readonly guardianService: GuardianService
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot
    ) {
        let currentPermissions = [];
        await this.guardianService.getGuardianData().then((res) => { //get current permissions for user
            res.forEach(entry => {
                if (entry.data.permissions && entry.data.permissions.length > 0) { 
                    currentPermissions = currentPermissions.concat(entry.data.permissions); //append all into current permissions list
                }
            })
            }).catch(error => {
                console.log(error);
                throw (error);
            });
            const requiredRoles = route.data.requiredRoles;
            // Allow the user to proceed if no additional roles are required to access the route.
            if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
                console.log("no required roles")
                return true;
            }

            // Allow the user to proceed if all the required roles are present.
            if (requiredRoles.every((role) => currentPermissions.includes(role))) {
                return true;
            } else {
                this.router.navigate(['unauthorized']);
                return false;
            }
    }
}