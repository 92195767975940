import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderPreferenceService } from './provider-preference.service';
import VerificationEmailData from '../models/VerificationEmailData';

@Injectable({
  providedIn: 'root'
})
export class SendVerificationEmailService {
  emailUrl: string;
  token: string;

  constructor(private readonly httpClient: HttpClient, private readonly bearerTokenService: ProviderPreferenceService) {
    this.emailUrl = environment.consentMgmt.preferenceUrl;
  }

  async sendVerificationEmail(
    sendEmailData: VerificationEmailData,
    complete: () => void,
    errorCallback?: (err: unknown) => void
  ) {
    const bearerTokenRequest = await this.bearerTokenService.getBearerToken();

    bearerTokenRequest
      .pipe(
        map((token) => this.httpClient.post<string>(`${this.emailUrl}/sendVerificationEmail`, sendEmailData, {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
              'Authorization': `Bearer ${token}`
            },
            observe: 'response'
          })),
        catchError((err) => {
          console.log("Couldn't get access token", err);
          return throwError(err);
        })
      ).subscribe({
        error: err => {
          // If an error function has been passed in, call it with the error
          if (errorCallback) {
            errorCallback(err);
          }

          complete();
        },
        complete: () => { complete(); }
      });
  }
}
