<div>
    <uitk-notification
    class="uitk-u-margin-bottom--lg"
    uitk-id="notificationVariant"
    [variant]="notificationVariant"
    [show]="true"
    >
    <span>{{ notificationMessage }}
    </span>
    </uitk-notification>
</div>
